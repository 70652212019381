import Smartlook from 'smartlook-client'

export const trackSmartlookEvent = (eventName: string, eventDetails: any = {}) => {
  Smartlook.track(eventName, eventDetails)
}

export const trackSmartlookEventOnLimitedRequest = (limitType?: string, name?: string, options: any = {}) => {
  switch (limitType) {
    case 'generations':
      trackSmartlookEvent('generate_progression', options)
      break
    case 'suggestions':
      trackSmartlookEvent('get_chord_suggestions', options)
      break
    case 'exports':
      if (name?.includes('MP3')) {
        trackSmartlookEvent('export_mp3', options)
      } else if (name?.includes('MIDI')) {
        trackSmartlookEvent('export_midi', options)
      } else if (name?.includes('PDF')) {
        trackSmartlookEvent('export_pdf', options)
      } else if (name?.includes('WAV')) {
        trackSmartlookEvent('export_wav', options)
      }
      break
    case 'voicings':
      trackSmartlookEvent('get_random_voicing', options)
      break
    case 'lyrics_suggestions':
      trackSmartlookEvent('get_lyrics_suggestion', options)
      break
    case 'rag':
    case 'aiDemo':
      trackSmartlookEvent('get_rag', options)
      break
    case 'shares':
      trackSmartlookEvent('share_preview', options)
      break
    case 'projects':
      trackSmartlookEvent('save_project', options)
      break
    default:
      break
  }
}
