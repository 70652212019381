import dynamic from 'next/dynamic'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'

import { checkLimitsInnerRoute, exportMidiInnerRoute, exportPdfInnerRoute } from '../api/exports'
import { usePlayerConfigState } from '../components/editor/hooks/usePlayerConfigState'
import useLimitedRequest from '../hooks/useLimitedRequest'
import {
  floatBufferToBlobMp3,
  floatBufferToBlobWav,
  generateFileName,
  getProgDurationTicks,
} from '../utils/audio/exportUtils'
import { drumsToMidi } from '../utils/audio/midiUtils'
import { LimitTypesLabel } from '../utils/limits'
import { useInternationalization } from './InternationalizationContext'

const MidiExportModal = dynamic(() => import('../components/common/ExportModals/MidiExportModal'))
const Mp3ExportModal = dynamic(() => import('../components/common/ExportModals/Mp3ExportModal'))
const PdfExportModal = dynamic(() => import('../components/common/ExportModals/PdfExportModal'))
const WavExportModal = dynamic(() => import('../components/common/ExportModals/WavExportModal'))

export type TExportType = 'midi' | 'wav' | 'mp3' | 'pdf'
type TMidiExportData = {
  chordsBlob: any
  melodyBlob: any
  drumsBlob: any
  name: string
}

type FetchType = {
  isLimitsLoading: boolean
  isExportLoading: boolean

  fileName: string
  midiExportData: TMidiExportData | null
  loops: number
  setLoops: (v: number | ((v: number) => number)) => void

  handleOpenExport: (type: TExportType) => void
  handlePrevStep: () => void
  handleNextStep: () => void
  handleCloseExport: () => void

  handleCheckExportLimits: () => Promise<boolean>
  handleExport: () => Promise<void>
}

export const ExportContext = React.createContext<FetchType>({
  isLimitsLoading: false,
  isExportLoading: false,

  fileName: '',
  midiExportData: null,
  loops: 0,
  setLoops: () => {},

  handleOpenExport: () => {},
  handlePrevStep: () => {},
  handleNextStep: () => {},
  handleCloseExport: () => {},

  handleCheckExportLimits: () => new Promise(() => false),
  handleExport: () => new Promise(() => {}),
})

const exportSteps = {
  midi: ['loading', 'settings'],
  wav: ['mode-selection', 'settings', 'loading'],
  mp3: ['settings', 'loading'],
  pdf: ['loading'],
}

export const ExportProvider = ({ children }: { children: ReactNode }) => {
  const { addComponentText } = useInternationalization()
  const { playerConfig } = usePlayerConfigState()
  const { prog, bpm, player, lyrics } = playerConfig

  const [exportStep, setExportStep] = useState(-1)
  const [exportType, setExportType] = useState<TExportType | null>(null)

  const [isLimitsLoading, setIsLimitsLoading] = useState(false)

  const [loops, setLoops] = useState(1)
  const [midiExportData, setMidiExportData] = useState<TMidiExportData | null>(null)

  const isMidiExportOpen = useMemo(() => exportType === 'midi' && exportStep !== -1, [exportStep, exportType])
  const isWavExportOpen = useMemo(() => exportType === 'wav' && exportStep !== -1, [exportStep, exportType])
  const isMp3ExportOpen = useMemo(() => exportType === 'mp3' && exportStep !== -1, [exportStep, exportType])
  const isPdfExportOpen = useMemo(() => exportType === 'pdf' && exportStep !== -1, [exportStep, exportType])

  const exportState = useMemo(() => (exportType ? exportSteps[exportType][exportStep] : null), [exportStep, exportType])
  const limitsTitle = useMemo(() => {
    switch (exportType) {
      case 'midi':
        return LimitTypesLabel.exportsMidi
      case 'mp3':
        return LimitTypesLabel.exportsMP3
      case 'wav':
        return LimitTypesLabel.exportsWAV
      case 'pdf':
        return LimitTypesLabel.exportsPDF
      default:
        return undefined
    }
  }, [exportType])

  const fileName = prog
    ? generateFileName(
        prog.name
          .replace(/([^a-z0-9 ]+)/gi, '')
          .replace(/\s\s+/g, ' ')
          .replace(' ', '-'),
        bpm,
        prog.key,
        prog.scale,
      )
    : ''

  useEffect(() => {
    addComponentText('ExportContext')
  }, [])

  useEffect(() => {
    if (exportType && ['midi', 'pdf'].includes(exportType) && exportStep === 0) handleExport(true)
  }, [exportType, exportStep])

  const handleOpenExport = (type: TExportType) => {
    if (exportType !== null || exportStep !== -1) return

    setExportType(type)
    setExportStep(0)
    player.pause()
  }
  const handlePrevStep = () => {
    setExportStep((v) => {
      const nextExportStep = v - 1
      if (nextExportStep === -1) setExportType(null)
      return nextExportStep
    })
  }
  const handleNextStep = (prevent?: boolean) => {
    if (!exportType || prevent) return

    const typeSteps = exportSteps[exportType]

    setExportStep((v) => {
      const nextExportStep = v + 1
      const lastExportStep = typeSteps.length - 1

      if (nextExportStep > lastExportStep) {
        setExportType(null)
        return -1
      }

      return nextExportStep
    })
  }
  const handleCloseExport = () => {
    setExportStep(-1)
    setExportType(null)

    setLoops(1)
  }

  const handleCheckExportLimits = useLimitedRequest(
    async () => {
      if (!exportType) return
      setIsLimitsLoading(true)
      await checkLimitsInnerRoute(exportType).finally(() => setIsLimitsLoading(false))
    },
    false,
    limitsTitle,
    'export',
  )

  const handleMIDIExportLimited = async () => {
    if (!prog) return
    const drumsList = prog.parts.map((p) => ({
      ...getProgDurationTicks(p),
      loops: p.loops,
      midi: drumsToMidi(p.drums),
    }))
    const data = await exportMidiInnerRoute(prog, bpm, JSON.stringify(drumsList), fileName)
    setMidiExportData(data)
  }
  const handleMP3ExportLimited = async () => {
    if (!prog) return
    const buffer = await player.exportBuffer(prog, bpm, loops)
    await floatBufferToBlobMp3(buffer, fileName)
  }
  const handleWAVExportLimited = async () => {
    if (!prog) return
    const buffer = await player.exportBuffer(prog, bpm, loops)
    await floatBufferToBlobWav(buffer, fileName)
  }
  const handlePDFExportLimited = async () => {
    if (!prog) return
    await exportPdfInnerRoute(fileName, lyrics)
  }

  const handleExport = async (preventStepBefore?: boolean, preventStepAfter?: boolean) => {
    const valid = await handleCheckExportLimits()
    if (!valid) return handleCloseExport()

    player.pause()

    handleNextStep(preventStepBefore)

    await new Promise(async (resolve) => {
      if (exportType === 'midi') await handleMIDIExportLimited()
      if (exportType === 'mp3') await handleMP3ExportLimited()
      if (exportType === 'wav') await handleWAVExportLimited()
      if (exportType === 'pdf') await handlePDFExportLimited()

      resolve(null)
    }).finally(() => handleNextStep(preventStepAfter))
  }

  return (
    <ExportContext.Provider
      value={{
        isLimitsLoading,
        isExportLoading: exportState === 'loading',

        fileName,
        midiExportData,
        loops,
        setLoops,

        handleOpenExport,
        handlePrevStep,
        handleNextStep,
        handleCloseExport,

        handleCheckExportLimits,
        handleExport,
      }}
    >
      <MidiExportModal isOpen={isMidiExportOpen} state={exportState} />
      <WavExportModal isOpen={isWavExportOpen} state={exportState} />
      <Mp3ExportModal isOpen={isMp3ExportOpen} state={exportState} />
      <PdfExportModal isOpen={isPdfExportOpen} state={exportState} />
      {children}
    </ExportContext.Provider>
  )
}

export const useExport = () => React.useContext<FetchType>(ExportContext)
