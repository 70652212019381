import classNames from 'classnames'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'

import NoteBookIcon from '../../../assets/icons/notebook.svg'
import { useCommonModals } from '../../../context/CommonModalsContext'
import { useCurrentUser } from '../../../context/CurrentUserContext'
import { useInternationalization } from '../../../context/InternationalizationContext'
import useSizes from '../../../hooks/useSizes'
import { trackPixelCustomEvent, trackPixelEvent } from '../../../utils/pixelUtils'
import Routes from '../../../utils/routes'
import DropdownMenu from '../../common/DropdownMenu/DropdownMenu'
import IconMenuButton from '../../common/IconMenuButton'
import Link from '../../common/Link/Link'
import { headerDisableIdsClickOutsideHook } from '../../editor/Header/Header'
import styles from './CommonHeader.module.scss'
import Logo from './Logo'
import MobileMenu from './MobileMenu/MobileMenu'
import ProfileMenu from './ProfileMenu/ProfileMenu'

export const HEADER_MODES = {
  PLAIN: 'PLAIN',
  PREVIEW: 'PREVIEW',
  LANDING: 'LANDING',
  GUIDE: 'GUIDE',
  GENERATOR: 'GENERATOR',
}

const ModesValues = Object.values(HEADER_MODES)

export type HeaderMode = typeof ModesValues[number]

export const HEADER_THEMES = {
  DARK: 'DARK',
  LIGHT: 'LIGHT',
}

const ThemesValues = Object.values(HEADER_THEMES)

export type HeaderTheme = typeof ThemesValues[number]

type Props = {
  scrollable?: boolean
  scrolled?: boolean
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
  centerContent?: React.ReactNode
  bottomMobileContent?: React.ReactNode
  className?: string
  color?: string
  mode?: HeaderMode
  couponMode?: HeaderMode
  renderMobileMenuContent?: () => JSX.Element
  theme?: HeaderTheme
  showCoupon?: boolean
}

export const getResourcesMenuItems = (
  STRINGS: { [key: string]: string },
  isMobile: boolean | null,
  isOption = false,
) => {
  const menuItems = [
    {
      content: (
        <div>
          <span>{STRINGS.blog}</span>
          <p>{STRINGS.blogDesc}</p>
        </div>
      ),
      className: classNames(styles.dropdownOption, styles.green),
      key: 1,
      href: Routes.BLOG,
    },
    {
      content: (
        <div>
          <span>{STRINGS.freeSeoTools}</span>
          <p>{STRINGS.freeSeoToolsDescription}</p>
        </div>
      ),
      className: styles.dropdownOption,
      key: 2,
      href: Routes.FREE_SEO_TOOLS,
    },
    {
      content: (
        <div>
          <span>{STRINGS.howToMakeSongGuide}</span>
          <p>{STRINGS.howToMakeSongGuideDesc}</p>
        </div>
      ),
      className: styles.dropdownOption,
      key: 3,
      href: Routes.HOW_TO_MAKE_A_SONG_GUIDE,
    },
    {
      content: (
        <div>
          <span>{STRINGS.education}</span>
          <p>{STRINGS.educationDesc}</p>
        </div>
      ),
      className: styles.dropdownOption,
      key: 4,
      href: Routes.EDU,
    },
    {
      content: (
        <div>
          <span>{STRINGS.canvas}</span>
          <p>{STRINGS.canvasDesc}</p>
        </div>
      ),
      className: styles.dropdownOption,
      key: 5,
      href: Routes.CANVAS,
    },
    ...(isMobile
      ? [
          {
            content: (
              <div>
                <span>{STRINGS.menuPricing}</span>
                <p>{STRINGS.menuPricingDesc}</p>
              </div>
            ),
            className: styles.dropdownOption,
            key: 6,
            href: Routes.PRICING,
          },
        ]
      : []),
  ]

  if (isOption) {
    return {
      name: STRINGS.resources,
      icon: <NoteBookIcon />,
      key: 'resources',
      sub: menuItems,
    }
  }
  return menuItems
}

const CommonHeader: React.FC<Props> = ({
  scrollable,
  scrolled,
  mode = HEADER_MODES.PLAIN,
  leftContent,
  rightContent,
  centerContent,
  bottomMobileContent,
  renderMobileMenuContent,
  color,
  theme = HEADER_THEMES.DARK,
  className,
  showCoupon,
}) => {
  const { addComponentText, getText } = useInternationalization()
  const { currentUser } = useCurrentUser()
  const router = useRouter()
  const { isMobile, isTablet, isSmallTablet } = useSizes()
  const modalsConfig = useCommonModals()

  const generatorButtonRef = useRef<HTMLInputElement>()

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  useEffect(() => {
    addComponentText('CommonHeader')
  }, [])

  const STRINGS = getText('CommonHeader')

  const previewOrGenerator = [HEADER_MODES.GENERATOR, HEADER_MODES.PREVIEW].includes(mode)

  const renderPlatinumButton = () => {
    const shouldRenderPlatinumBtn = currentUser ? currentUser.subscriptionPlan === 'free' : true
    if (!shouldRenderPlatinumBtn) return <></>
    return (
      <IconMenuButton
        className={classNames(styles.tryPlatinumButton, {
          [styles.generator]: mode === HEADER_MODES.GENERATOR,
        })}
        isHoverWhite={false}
        text={
          isTablet && !isMobile
            ? ''
            : currentUser?.prefs?.isTrialUsed
            ? STRINGS.tryPlatinumNoTrial
            : STRINGS.tryPlatinum
        }
        icon='platinum'
        onClick={() => {
          trackPixelEvent('TryPlatinumClick')
          modalsConfig.setTryPlatinumOpen('default', 'click_try_platinum')
          modalsConfig.setPricingOpen(true)
        }}
      />
    )
  }
  const darkDropdown =
    mode === HEADER_MODES.GENERATOR ||
    mode === HEADER_MODES.GUIDE ||
    mode === HEADER_MODES.LANDING ||
    mode === HEADER_MODES.PREVIEW
  const mobileViewDropDown = mode !== HEADER_MODES.GUIDE && mode !== HEADER_MODES.LANDING && !!isMobile
  const renderBlogButton = () => {
    const menuItems = getResourcesMenuItems(STRINGS, isMobile) as any
    return (
      <DropdownMenu
        generatorComponent={darkDropdown}
        mobileView={mobileViewDropDown}
        openOnHover
        options={
          mobileViewDropDown
            ? [
                [
                  {
                    content: <div>{STRINGS.resources}</div>,
                    className: styles.blogMenuItemMobile,
                    icon: <NoteBookIcon />,
                    key: 1,
                    sub: menuItems,
                  },
                ],
              ]
            : [menuItems]
        }
        className={classNames(styles.dropdownMenu, {
          [styles.light]: !darkDropdown,
          [styles.mobileView]: mobileViewDropDown,
        })}
        renderTrigger={(onClick, active) =>
          mobileViewDropDown ? (
            <span />
          ) : (
            <IconMenuButton
              className={classNames(styles.blogMenuItem, {
                [styles.active]: active,
                [styles.landing]:
                  mode === HEADER_MODES.GUIDE ||
                  mode === HEADER_MODES.LANDING ||
                  (!isMobile && mode === HEADER_MODES.PREVIEW),
                [styles.generator]: mode === HEADER_MODES.GENERATOR || (isMobile && mode === HEADER_MODES.PREVIEW),
              })}
              onClick={onClick}
              text={STRINGS.resources}
              isHoverBg
              isHoverWhite
              icon='notebook'
            />
          )
        }
      />
    )
  }
  const renderBurger = () => (
    <IconMenuButton
      onClick={() => setMobileMenuOpen(true)}
      className={classNames(styles.mobileMenuButton, {
        [styles.generator]: previewOrGenerator,
      })}
      isHoverWhite={false}
      icon='burger'
    />
  )

  const renderPricingButton = () => (
    <Link
      href={Routes.PRICING}
      data-active={router.pathname === Routes.PRICING}
      className={classNames(styles.pricingButton, {
        [styles.landing]: mode === HEADER_MODES.GUIDE || mode === HEADER_MODES.LANDING || mode === HEADER_MODES.PREVIEW,
      })}
    >
      {STRINGS.menuPricing}
    </Link>
  )

  const renderGeneratorButton = (hidden?: boolean) => (
    <Link
      href={Routes.GENERATOR}
      onClick={() => trackPixelCustomEvent('GeneratorButtonClick')}
      data-hidden={hidden}
      className={styles.createProjectButton}
      data-preivew={mode === HEADER_MODES.PREVIEW}
    >
      {STRINGS.menuCreateProject}
    </Link>
  )

  const isSmallLogo =
    (isMobile && (mode === HEADER_MODES.LANDING || mode === HEADER_MODES.GUIDE)) ||
    (isTablet && mode === HEADER_MODES.GENERATOR)
  if (isMobile === null) {
    return null
  }
  return (
    <>
      {scrollable ? (
        <div
          className={styles.stickyGeneratorButton}
          data-hidden={!scrolled}
          style={{
            left: generatorButtonRef.current?.getBoundingClientRect().left,
            top: 12,
            width: generatorButtonRef.current?.offsetWidth,
          }}
        >
          {renderGeneratorButton(!scrolled)}
        </div>
      ) : null}
      {isMobile && (
        <MobileMenu
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          renderPlatinumButton={renderPlatinumButton}
          renderContent={renderMobileMenuContent}
          generatorComponent={previewOrGenerator}
        />
      )}
      {/*{showCoupon ? <PaddleCouponHeader mode={couponMode || ''} /> : null}*/}
      <header
        style={{ background: color }}
        className={classNames(
          styles.header,
          {
            [styles.dark]: mode !== HEADER_MODES.PLAIN,
            [styles.darkLanding]: mode === HEADER_MODES.LANDING || mode === HEADER_MODES.GUIDE,
            [styles.zoomed]: previewOrGenerator,
            [styles.scrollable]: scrollable,
            [styles.showCoupon]: showCoupon,
          },
          className,
        )}
        data-disable-ids-click-outside-hook={headerDisableIdsClickOutsideHook}
      >
        <div
          className={classNames(styles.content, { [styles.centerDominate]: mode === HEADER_MODES.GENERATOR })}
          data-landing={HEADER_MODES.LANDING === mode}
        >
          <div className={styles.flexLeft}>
            {isMobile && previewOrGenerator ? renderBurger() : null}
            {(!isMobile || !previewOrGenerator) && (
              <Link className={styles.logo} href={Routes.LANDING} data-small={isSmallLogo} aria-label='Logo'>
                <Logo small={isSmallLogo || (isSmallTablet && mode === HEADER_MODES.PREVIEW)} />
              </Link>
            )}
            {leftContent || null}
          </div>
          {centerContent ? <div className={styles.flexCenter}>{centerContent}</div> : null}
          <div className={styles.flexRight}>
            {rightContent || null}
            {(!isMobile || mode === HEADER_MODES.LANDING || mode === HEADER_MODES.GUIDE) &&
            mode !== HEADER_MODES.GENERATOR ? (
              <>
                {!isMobile && renderPricingButton()}
                {renderBlogButton()}
                {/* @ts-ignore */}
                <div ref={generatorButtonRef}>{renderGeneratorButton(scrollable && scrolled)}</div>
              </>
            ) : null}
            {mode !== HEADER_MODES.LANDING && (
              <>
                {isMobile && mode === HEADER_MODES.PLAIN && renderBurger()}
                {!isMobile && (
                  <ProfileMenu
                    generatorComponent={previewOrGenerator || mode === HEADER_MODES.GUIDE}
                    theme={theme}
                    platinumButton={renderPlatinumButton()}
                  />
                )}
                {isMobile && mode === HEADER_MODES.PREVIEW && (
                  <Link className={`${styles.logo} ${styles.logoRight}`} href={Routes.LANDING}>
                    <Logo small />
                  </Link>
                )}
              </>
            )}
          </div>
        </div>
        {bottomMobileContent}
      </header>
    </>
  )
}

export default CommonHeader
