import { limitTypeType } from '../components/signup/login/LoginModal/LoginModal'
import { useCommonModals } from '../context/CommonModalsContext'
import { useCurrentUser } from '../context/CurrentUserContext'
import { LimitTypesLabel } from '../utils/limits'
import { trackSmartlookEventOnLimitedRequest } from '../utils/smartlookUtils'

function useLimitedRequest(
  func: (...params: any[]) => Promise<any>,
  plainTryPlatinum?: boolean,
  name?: LimitTypesLabel,
  limitType?: limitTypeType,
  errorAction: any = () => {},
) {
  const modalsConfig = useCommonModals()
  const { currentUser } = useCurrentUser()

  return async (...params: any[]) => {
    try {
      const data = await func(...params)
      trackSmartlookEventOnLimitedRequest(limitType, name, params)
      return data || true
    } catch (e: any) {
      errorAction()
      if (e.response?.status === 403 || e.response?.status === 401) {
        if (!currentUser) {
          modalsConfig.setLoginOpen(true)
          modalsConfig.setLoginLimitName(name)
          modalsConfig.setLoginLimitType(limitType)
        } else {
          modalsConfig.setTryPlatinumUpgradeTitle(name)
          modalsConfig.setTryPlatinumOpen(plainTryPlatinum ? 'default' : 'upgrade', name)
          
          modalsConfig.setPricingOpen(true)
          modalsConfig.setPricingLimited(true)
        }
        return false
      }
      console.error(e)
      throw new Error(e)
    }
  }
}

export default useLimitedRequest
