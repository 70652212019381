import classNames from 'classnames'
import React from 'react'

import CommentsIcon from '../../../../assets/icons/comments.svg'
import LogoutIcon from '../../../../assets/icons/logout.svg'
import SettingsIcon from '../../../../assets/icons/settings.svg'
import SmallUserIcon from '../../../../assets/icons/small-user.svg'
import TutorialsIcon from '../../../../assets/icons/tutorials.svg'
import { useCommonModals } from '../../../../context/CommonModalsContext'
import { useCurrentUser } from '../../../../context/CurrentUserContext'
import { useInternationalization } from '../../../../context/InternationalizationContext'
import useIsActiveRoute from '../../../../hooks/useIsActiveRoute'
import useSizes from '../../../../hooks/useSizes'
import Routes from '../../../../utils/routes'
import routes from '../../../../utils/routes'
import { windowOpen } from '../../../../utils/stringUtils'
import DropdownMenu from '../../../common/DropdownMenu/DropdownMenu'
import IconMenuButton from '../../../common/IconMenuButton'
import { usePlayerConfigState } from '../../../editor/hooks/usePlayerConfigState'
import { getPlanIconByMode } from '../../CommonModals/TryPlatinumModal/try-platinum-types'
import { HEADER_THEMES, HeaderTheme } from '../CommonHeader'
import styles from './ProfileMenu.module.scss'

type Props = {
  generatorComponent?: boolean
  renderMenu?: () => JSX.Element
  theme?: HeaderTheme
  platinumButton?: any
}

const ProfileMenu: React.FC<Props> = ({ generatorComponent, theme = HEADER_THEMES.DARK, platinumButton }) => {
  const { getText } = useInternationalization()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { currentUser } = useCurrentUser()
  const { logout } = useCurrentUser()
  const isLibrary = useIsActiveRoute(Routes.LIBRARY)
  const isSettings = useIsActiveRoute(Routes.SETTINGS)
  const { isMobile } = useSizes()
  const modalsConfig = useCommonModals()
  const buttonStyles = [
    styles.profileButton,
    currentUser ? styles.active : '',
    generatorComponent ? styles.dark : styles.light,
  ].join(' ')

  const STRINGS = getText('ProfileMenu')

  const renderPlanIcon = () => {
    switch (currentUser?.subscriptionPlan) {
      case 'free':
        if (theme === HEADER_THEMES.DARK) {
          return <img src={STRINGS.freeIconPath} className={styles.illustration} />
        }
        return <img src={STRINGS.freeIconDarkPath} className={styles.illustration} />
      case 'multi_platinum_monthly':
      case 'multi_platinum_yearly':
        return <img src={getPlanIconByMode('multi_platinum', 'svg')} className={styles.illustration} />
      case 'premium_weekly':
      case 'premium_monthly':
      case 'premium_3-month':
      case 'premium_6-month':
      case 'premium_yearly':
        return <img src={getPlanIconByMode('premium', 'svg')} className={styles.illustration} />
      case 'platinum_monthly':
      case 'platinum_yearly':
      case 'pro_monthly':
      case 'pro_yearly':
        return <img src={getPlanIconByMode('platinum', 'svg')} className={styles.illustration} />
      default:
        return <></>
    }
  }
  const renderLoginButton = () => (
    <div className={classNames(styles.loginButton, styles[generatorComponent ? 'dark' : 'light'])}>
      <IconMenuButton
        icon='user'
        text={STRINGS.login}
        className={styles.loginButtonContent}
        onClick={() => modalsConfig.setLoginOpen(true)}
        isHoverBg
        isHoverWhite
      />
    </div>
  )
  const renderButton = (onClick?: () => void) => (
    <div
      className={classNames(styles.profileButton, styles[generatorComponent ? 'dark' : 'light'], {
        [styles.noSubscription]: currentUser?.subscriptionPlan === 'free',
      })}
    >
      <IconMenuButton
        icon='user'
        className={styles.profileButtonContent}
        onClick={onClick || (() => modalsConfig.setLoginOpen(true))}
      />
      <div className={styles.profileButtonPlan}>{renderPlanIcon()}</div>
    </div>
  )
  const renderMobileButton = () => (
    <div onClick={() => modalsConfig.setLoginOpen(true)} className={buttonStyles}>
      <IconMenuButton icon='user' className={styles.profileButtonContent} />
      {STRINGS.login}
    </div>
  )

  const renderMenu = () => (
    <DropdownMenu
      mobileView={isMobile}
      headerContent={platinumButton}
      options={[
        [
          {
            name: STRINGS.library,
            href: Routes.LIBRARY,
            key: 1,
            icon: <SmallUserIcon />,
            active: isLibrary,
          },
          {
            name: STRINGS.settings,
            href: Routes.SETTINGS,
            key: 2,
            icon: <SettingsIcon />,
            active: isSettings,
          },
          {
            name: STRINGS.feedback,
            key: 3,
            icon: <CommentsIcon />,
            onClick: () => windowOpen(routes.FEEDBACK_FRILL),
          },
          ...(isMobile
            ? []
            : [
                {
                  name: STRINGS.tutorials,
                  key: 4,
                  icon: <TutorialsIcon />,
                  onClick: () => {
                    playerConfigSetter.setView({ onboardingOpen: true })
                  },
                },
              ]),
        ],
        [
          {
            name: STRINGS.logout,
            key: 5,
            className: styles.footerOption,
            icon: <LogoutIcon />,
            onClick: logout,
          },
        ],
      ]}
      className={styles.dropdownMenu}
      renderTrigger={renderButton}
      generatorComponent={generatorComponent}
    />
  )
  return (
    <>
      <div className={styles.container}>
        {currentUser ? renderMenu() : isMobile ? renderMobileButton() : renderLoginButton()}
      </div>
    </>
  )
}

export default ProfileMenu
