import React from 'react'

import Routes from '../../../../utils/routes'
import routes from '../../../../utils/routes'
import IconButton from '../../../common/IconButton'
import Link from '../../../common/Link/Link'
import Logo from '../Logo'
import ProfileMenu from '../ProfileMenu/ProfileMenu'
import styles from './MobileMenu.module.scss'

type Props = {
  open: boolean
  generatorComponent?: boolean
  onClose: () => void
  renderContent?: () => JSX.Element
  renderPlatinumButton: () => JSX.Element
}

const STRINGS = {
  menuCreateProject: 'Generator',
}

const MobileMenu: React.FC<Props> = ({
  open,
  onClose,
  renderContent,
  generatorComponent,
  renderPlatinumButton,
}: Props) => {
  return (
    <>
      {open && <div className={styles.bgShadow} onClick={() => onClose()} />}
      <div
        className={[styles.container, generatorComponent ? styles.dark : ''].join(' ')}
        style={{
          width: open ? '75vw' : 0,
        }}
      >
        <div
          className={styles.content}
          style={{
            opacity: open ? 1 : 0,
          }}
        >
          <div className={styles.header}>
            <Link href={routes.LANDING}>
              <Logo />
            </Link>
            <IconButton icon='close' onClick={onClose} className={styles.closeIcon} />
          </div>
          {renderPlatinumButton()}
          <div className={styles.body}>
            {renderContent ? <div className={styles.externalContent}>{renderContent()}</div> : null}
            <ProfileMenu generatorComponent={generatorComponent} />
          </div>
          <div className={styles.footer}>
            {generatorComponent ? null : (
              <Link href={Routes.GENERATOR} className={styles.createProjectButton}>
                {STRINGS.menuCreateProject}
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileMenu
