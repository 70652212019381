export const priceModes = ['platinum', 'multi_platinum', 'diamond', 'premium'] as const
export type PriceMode = typeof priceModes[number]

export type PriceConfig = {
  title: string
  monthlyPrice: number
  yearlyPrice: number
  yearlyMonthPrice: string
  yearlyDiscountPrice?: number
  chords: number | string
  generations: number | string
  exports: string
  shares: string
  lyrics: string
}

export const getPlanIconByMode = (mode: PriceMode, extension = 'png') => {
  return `/static/images/disk/${mode}.${extension}`
}

export const getModeFromPlan = (plan: string) => {
  const parts = plan.split('_')
  return parts.slice(0, parts.length - 1).join('_') as PriceMode
}

export const getIsYearlyFromPlan = (plan: string) => {
  return plan.split('_').pop() === 'yearly'
}

export const getPlanFromModeAndYearly = (mode: PriceMode, isYearly: boolean) => {
  return mode + (isYearly ? '_yearly' : '_monthly')
}
