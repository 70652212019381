import { hjEvent } from '../utils/hotjar'
import { windowOpen } from '../utils/stringUtils'
import { Lyrics, Prog } from '../utils/types'
import api from './index'

export const checkLimitsInnerRoute = async (type: string) => {
  await api.post('/api/export/limits', { type })
}

export const exportPdfInnerRoute = async (progName: string, lyrics: Lyrics) => {
  hjEvent('event', 'export_pdf')
  windowOpen(`/api/export/pdf?lyrics=${encodeURIComponent(JSON.stringify(lyrics || {}))}&progName=${progName}`)
}

export const exportMidiInnerRoute = async (prog: Prog, bpm: number, drumsList: string, name: string) => {
  const [chordsBlob, melodyBlob, drumsBlob] = await Promise.all([
    api.post('/api/export/midi', { prog: JSON.stringify(prog), bpm }, { responseType: 'blob' }),
    api.post('/api/export/melody-midi', { prog: JSON.stringify(prog), bpm }, { responseType: 'blob' }),
    api.post(
      '/api/export/drums-midi',
      {
        bpm,
        drumsList,
        progName: prog.name,
      },
      { responseType: 'blob' },
    ),
  ])
  hjEvent('event', 'export_midi')
  return {
    chordsBlob,
    melodyBlob,
    drumsBlob,
    name,
  }
}
