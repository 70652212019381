import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { checkRagLimitsInnerRoute } from '../../../api/progresssions'
import MobileNavigationChords from '../../../assets/icons/mobile-navigation-chords.svg'
import MobileNavigationLyrics from '../../../assets/icons/mobile-navigation-lyrics.svg'
import MobileNavigationSettings from '../../../assets/icons/mobile-navigation-settings.svg'
import PromptGenerateIcon from '../../../assets/icons/prompt-generate.svg'
import { useCommonModals } from '../../../context/CommonModalsContext'
import { useCurrentUser } from '../../../context/CurrentUserContext'
import { useExport } from '../../../context/ExportContext'
import { useGeneratorModals } from '../../../context/GeneratorModalsContext'
import { useHistory } from '../../../context/HistoryContext'
import { useInstrumentsState } from '../../../context/InstrumentsStateContext'
import { useInternationalization } from '../../../context/InternationalizationContext'
import { useProjectState } from '../../../context/ProjectStateContext'
import { clickOutsideHookIds } from '../../../hooks/clickOutsideAlerterHook'
import useLimitedRequest from '../../../hooks/useLimitedRequest'
import useSizes from '../../../hooks/useSizes'
import { LimitTypesLabel } from '../../../utils/limits'
import { trackPixelEvent } from '../../../utils/pixelUtils'
import { TMobileTab } from '../../../utils/types'
import CircleLoader from '../../common/CircleLoader/CircleLoader'
import DropdownMenu from '../../common/DropdownMenu/DropdownMenu'
import IconMenuButton from '../../common/IconMenuButton'
import MobileSwipeableModal from '../../common/MobileSwipeableModal/MobileSwipeableModal'
import Tooltip from '../../common/Tooltip/Tooltip'
import CommonHeader, { HEADER_MODES } from '../../layout/CommonHeader/CommonHeader'
import { usePlayerConfigState } from '../hooks/usePlayerConfigState'
import styles from './Header.module.scss'
import TypeBeatTextIcon from './assets/typeBeat.svg'
import TypeBeatArrowIcon from './assets/typeBeatArrow.svg'

const ExportMenu = dynamic(() => import('../../common/ExportModals/ExportMenu/ExportMenu'))
const ShareMenu = dynamic(() => import('./ShareMenu/ShareMenu'))
const ImportMenu = dynamic(() => import('./ImportMenu/ImportMenu'))

type Props = {
  progName: string
  pageLoading?: boolean
  setProgName?: (s: string) => void
}

export const headerDisableIdsClickOutsideHook = [clickOutsideHookIds.footer, clickOutsideHookIds.timelineWrapper]

const Header: React.FC<Props> = ({ progName, pageLoading }) => {
  const { isUndoAvailable, isRedoAvailable, undoDescription, redoDescription, undo, redo } = useHistory()
  const { isMobile, isTablet } = useSizes()
  const { text, getText } = useInternationalization()
  const { currentUser } = useCurrentUser()
  const router = useRouter()
  const [isPromptStarter, setIsPromptStarter] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const projectStateConfig = useProjectState()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const generatorModalsConfig = useGeneratorModals()
  const { setupGeneratedLayers } = useInstrumentsState()
  const modalsConfig = useCommonModals()
  const { isExportLoading, isLimitsLoading } = useExport()

  const mobileSwipeableModalContentRef = useRef<HTMLDivElement>(null)

  const [exportOpen, setExportOpen] = useState(false)
  const [generationPromptLoading, setGenerationPromptLoading] = useState(false)
  const [generationPrompt, setGenerationPrompt] = useState('')

  const handleCheckLimits = useLimitedRequest(
    async () => {
      setGenerationPromptLoading(true)
      await checkRagLimitsInnerRoute()
    },
    false,
    LimitTypesLabel.rag,
    'rag',
  )
  useEffect(() => {
    if (isPromptStarter && isMobile) {
      inputRef.current?.focus()
    }
  }, [isPromptStarter])
  useEffect(() => {
    const urlParams = new URLSearchParams(router.asPath.split('?')[1])
    setIsPromptStarter(!!urlParams.get('prompt-starter'))
  }, [router.asPath])

  // LEFT
  const renderMenu = () => {
    const handleProjectSaveClick = () => {
      if (!currentUser) {
        modalsConfig.setLoginLimitName(LimitTypesLabel.projects)
        modalsConfig.setLoginLimitType('project')
        modalsConfig.setLoginOpen(true)
      } else {
        generatorModalsConfig.setProjectNameModalOpen(true)
      }
    }

    return <ImportMenu onProjectSave={handleProjectSaveClick} />
  }

  const renderProjectName = () => {
    if (isTablet) return null
    return (
      <>
        <div
          className={styles.projectName}
          data-unsaved={!projectStateConfig.editedProjectId}
          data-tooltip-id={'tooltip-projectName'}
          onClick={() => generatorModalsConfig.setProjectNameModalOpen(true)}
        >
          {progName}
        </div>
        <Tooltip id={'tooltip-projectName'} text={progName} place='bottom' />
      </>
    )
  }
  const renderUndoRedoButtons = () => {
    const undoTooltipId = 'undo-tooltip'
    const redoTooltipId = 'redo-tooltip'

    return (
      <>
        <IconMenuButton
          icon='arrow-revert'
          className={styles.undoButton}
          onClick={() => undo()}
          enabled={isUndoAvailable}
          dataTooltipId={undoTooltipId}
        />
        {undoDescription && (
          <Tooltip id={undoTooltipId} place='bottom'>
            <div className={styles.undoRedoDescription}>{undoDescription}</div>
          </Tooltip>
        )}
        <IconMenuButton
          icon='arrow-revert'
          className={styles.redoButton}
          onClick={() => redo()}
          enabled={isRedoAvailable}
          dataTooltipId={redoTooltipId}
        />
        {redoDescription && (
          <Tooltip id={redoTooltipId} place='bottom'>
            <div className={styles.undoRedoDescription}>{redoDescription}</div>
          </Tooltip>
        )}
      </>
    )
  }

  // CENTER
  const renderGenerateField = () => {
    const disabled = !generationPrompt.length
    const STRINGS = text.header.promptGenerator

    const handleGenerate = async () => {
      if (generationPromptLoading || disabled) return

      const valid = await handleCheckLimits()
      if (!valid) {
        window.history.replaceState(null, '', window.location.pathname)
        setIsPromptStarter(false)
        return setGenerationPromptLoading(false)
      }

      await setupGeneratedLayers(generationPrompt)
      window.history.replaceState(null, '', window.location.pathname)
      setIsPromptStarter(false)
      generatorModalsConfig.portalsConfig.setRandomDrumInstrument(false)
      generatorModalsConfig.portalsConfig.setRandomInstrument(false)
      setGenerationPromptLoading(false)
    }

    return (
      <>
        <div
          className={styles.generationField}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleGenerate()
          }}
        >
          <div className={styles.generationFieldInner}>
            <input
              size={1}
              ref={inputRef}
              type='text'
              placeholder={STRINGS.placeholder}
              value={generationPrompt}
              onChange={(e) => {
                const newPrompt = e.target.value
                if (newPrompt.length > 250) return
                setGenerationPrompt(newPrompt)
              }}
            />

            <div className={classNames(styles.generate, { [styles.disabled]: disabled })} onClick={handleGenerate}>
              {generationPromptLoading ? <CircleLoader /> : isTablet ? <PromptGenerateIcon /> : STRINGS.btn}
              <div className={styles.beta}>{STRINGS.beta}</div>
            </div>
          </div>
        </div>
        {isMobile &&
          !pageLoading &&
          isPromptStarter &&
          createPortal(
            <div className={styles.promptBlocker}>
              <TypeBeatTextIcon className={styles.promptBlockerText} />{' '}
              <TypeBeatArrowIcon className={styles.promptBlockerArrow} />
            </div>,
            document.getElementById('__next') as HTMLElement,
          )}
      </>
    )
  }
  const renderHeaderMobileNavigation = () => {
    const mobileTabs: { name: TMobileTab; icon: ReactNode }[] = [
      {
        name: 'chords',
        icon: <MobileNavigationChords />,
      },
      {
        name: 'lyrics',
        icon: <MobileNavigationLyrics />,
      },
      {
        name: 'settings',
        icon: <MobileNavigationSettings />,
      },
    ]

    return (
      <div className={styles.headerMobileNavigation}>
        {mobileTabs.map((el) => (
          <div
            className={classNames(styles.option, styles[el.name], {
              [styles.active]: playerConfig.view.mobileTab === el.name,
            })}
            onClick={() => playerConfigSetter.setView({ mobileTab: el.name })}
            key={el.name}
          >
            {el.icon}
          </div>
        ))}

        <div className={classNames(styles.marker, styles[playerConfig.view.mobileTab])}></div>
      </div>
    )
  }

  // RIGHT
  const renderExportMenuButton = () => {
    const STRINGS = getText('ExportMenu')

    const renderExportButton = (onClick: any) => (
      <div className={styles.exportButtonContainer}>
        <IconMenuButton
          onClick={() => {
            onClick()
            trackPixelEvent('ViewContent')
          }}
          className={styles.exportButton}
          isHoverWhite={false}
          enabled={!isExportLoading && !isLimitsLoading}
          loadingIcon={isExportLoading || isLimitsLoading}
          loaderClassname={styles.exportButtonLoader}
          text={isTablet ? '' : STRINGS.triggerBtn}
          icon='export'
        />
      </div>
    )
    const renderShareButton = (onClick: any) => (
      <IconMenuButton
        onClick={() => {
          onClick()
          trackPixelEvent('ViewContent')
        }}
        className={classNames(styles.exportButton, styles.small)}
        enabled={!isExportLoading && !isLimitsLoading}
        isHoverWhite={false}
        text={''}
        icon='share'
      />
    )
    const renderExportMenu = () => {
      return <ExportMenu onClose={() => setExportOpen(false)} />
    }
    const renderShareMenu = () => {
      return <ShareMenu open={exportOpen} onClose={() => setExportOpen(false)} />
    }
    if (isMobile) {
      return (
        <>
          <MobileSwipeableModal
            onClose={() => setExportOpen(false)}
            open={exportOpen}
            lowPriority
            getHeight={() => mobileSwipeableModalContentRef.current?.clientHeight}
          >
            <div className={styles.container} ref={mobileSwipeableModalContentRef}>
              {renderExportMenu()}
              {renderShareMenu()}
            </div>
          </MobileSwipeableModal>
          {renderExportButton(() => {
            setExportOpen(true)
          })}
        </>
      )
    }
    return (
      <>
        <DropdownMenu
          mobileView={isMobile}
          onMenuOpen={(val) => setExportOpen(val)}
          options={[
            [
              {
                content: renderExportMenu(),
                className: styles.dropdownOption,
                dontCloseOnSelect: true,
                key: 1,
                component: 'div',
              },
            ],
          ]}
          className={styles.dropdownMenu}
          renderTrigger={renderExportButton}
          generatorComponent
          clickOutsideDisabledIds={headerDisableIdsClickOutsideHook}
        />
        <DropdownMenu
          mobileView={isMobile}
          onMenuOpen={(val) => setExportOpen(val)}
          options={[
            [
              {
                content: renderShareMenu(),
                className: styles.dropdownOption,
                dontCloseOnSelect: true,
                key: 1,
                component: 'div',
              },
            ],
          ]}
          className={styles.dropdownMenuShare}
          renderTrigger={renderShareButton}
          generatorComponent
          clickOutsideDisabledIds={headerDisableIdsClickOutsideHook}
        />
      </>
    )
  }

  const headerProps = isMobile
    ? {
        renderMobileMenuContent: renderMenu,
        centerContent: renderHeaderMobileNavigation(),
        rightContent: renderExportMenuButton(),
        // bottomMobileContent: <>{renderGenerateField()}</>,
      }
    : {
        leftContent: (
          <>
            {renderMenu()}
            {renderProjectName()}
            {renderUndoRedoButtons()}
          </>
        ),
        centerContent: <>{renderGenerateField()}</>,
        rightContent: <>{renderExportMenuButton()}</>,
      }

  return <CommonHeader mode={HEADER_MODES.GENERATOR} {...headerProps} />
}

export default Header
