import { useEffect, useState } from 'react'

function useIsActiveRoute(route: string) {
  const [location, setLocation] = useState<Location | null>(null)
  useEffect(() => {
    setLocation(window.location)
  }, [])
  return location?.pathname.includes(route)
}

export default useIsActiveRoute
